import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { Helmet } from 'react-helmet'

import './layout.css'
import Footer from './footer'
import Header from './header'
import Main from './main'
import Navbar from '../navbar/navbar'

const Layout = ({ children, img }) => {
  return (
    <div className='container-fluid p-0'>
      <Helmet>
        <html lang='en' />

        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />

        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/manifest.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#39bbdf' />
        <meta name='msapplication-TileColor' content='#b91d47' />
        <meta name='theme-color' content='#ffffff' />
        <meta
          name='facebook-domain-verification'
          content='sf7536qlx5z7iuz4lfl2cfrpbes19v'
        />
      </Helmet>

      <Navbar />

      <Header img={img} />

      <Main>{children}</Main>

      <Footer />
    </div>
  )
}

export default Layout
