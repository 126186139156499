import React from 'react'
import PropTypes from 'prop-types'

import hotpotsJamboree from '../../images/the-lancashire-hotpots-jamboree-header.jpg'
import useSiteMetadata from '../../hooks/use-site-metadata'

const Header = ({ img }) => {
  const { title } = useSiteMetadata()
  img.alt = img.alt || title

  return (
    <header className='jumbotron jumbotron-fluid m-0 p-0'>
      <img src={img.src} className='img-fluid shadow' alt={img.alt} />
    </header>
  )
}

Header.propTypes = {
  img: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
  }),
}

Header.defaultProps = {
  img: {
    src: hotpotsJamboree,
  },
}

export default Header
