import React from 'react'
import PropTypes from 'prop-types'

const NavbarToggler = ({ label, target }) => {
  return (
    <button
      className='navbar-toggler'
      type='button'
      data-toggle='collapse'
      data-target={`#${target}`}
      aria-controls={target}
      aria-expanded='false'
      aria-label={label}
    >
      <span className='navbar-toggler-icon'></span>
    </button>
  )
}

NavbarToggler.propTypes = {
  label: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
}

NavbarToggler.defaultProps = {
  label: 'Toggle navigation',
}

export default NavbarToggler
