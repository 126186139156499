import React from 'react'
import { Link } from 'gatsby'

import logo from '../../images/the-lancashire-hotpots-icon.png'
import useSiteMetadata from '../../hooks/use-site-metadata'

const NavbarBrand = () => {
  const { title } = useSiteMetadata()
  return (
    <Link to='/' className='navbar-brand'>
      <img src={logo} alt={`${title} Logo`} height='40' className='mr-2' />
      <h1 className='small d-none d-sm-inline'>{title}</h1>
    </Link>
  )
}

export default NavbarBrand
