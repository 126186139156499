import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faApple,
  faFacebook,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTwitch,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

const NavbarSocial = () => {
  return (
    <div className='navbar-nav ml-auto'>
      <a
        href='https://en-gb.facebook.com/thelancashirehotpots/'
        className='nav-item nav-link'
      >
        <FontAwesomeIcon icon={faFacebook} />
        <span className='d-lg-none ml-1'>Facebook</span>
      </a>
      <a href='https://twitter.com/thehotpots' className='nav-item nav-link'>
        <FontAwesomeIcon icon={faTwitter} />
        <span className='d-lg-none ml-1'>Twitter</span>
      </a>
      <a
        href='https://www.instagram.com/thelancashirehotpots/'
        className='nav-item nav-link'
      >
        <FontAwesomeIcon icon={faInstagram} />
        <span className='d-lg-none ml-1'>Instagram</span>
      </a>
      <a
        href='https://www.youtube.com/playlist?playnext=1&list=PLxyJRwODGYJySdz5lLK86dSc0rIoL-A8Z&feature=gws_kp_artist'
        className='nav-item nav-link'
      >
        <FontAwesomeIcon icon={faYoutube} />
        <span className='d-lg-none ml-1'>Youtube</span>
      </a>
      <a
        href='https://itunes.apple.com/gb/artist/the-lancashire-hotpots/218848312'
        className='nav-item nav-link'
      >
        <FontAwesomeIcon icon={faApple} />
        <span className='d-lg-none ml-1'>Apple Music</span>
      </a>
      <a
        href='https://open.spotify.com/artist/5ac41g3jUH9rBoe6zawiaB?autoplay=true&v=A'
        className='nav-item nav-link'
      >
        <FontAwesomeIcon icon={faSpotify} />
        <span className='d-lg-none ml-1'>Spotify</span>
      </a>
      <a
        href='https://soundcloud.com/the-lancashire-hotpots'
        className='nav-item nav-link'
      >
        <FontAwesomeIcon icon={faSoundcloud} />
        <span className='d-lg-none ml-1'>SoundCloud</span>
      </a>
      <a
        href='https://www.twitch.tv/thelancashirehotpots'
        className='nav-item nav-link'
      >
        <FontAwesomeIcon icon={faTwitch} />
        <span className='d-lg-none ml-1'>Twitch</span>
      </a>
    </div>
  )
}

export default NavbarSocial
