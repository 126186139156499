import React from 'react'
import { Link } from 'gatsby'

import useSiteMetadata from '../../hooks/use-site-metadata'

const Footer = () => {
  const { title } = useSiteMetadata()

  return (
    <footer className='bg-warning'>
      <div className='container'>
        <p className='small text-center m-0 py-2'>
          © {title} {new Date().getFullYear()} |{' '}
          <Link to='/privacy/' className='text-dark'>
            Privacy
          </Link>
        </p>
      </div>
    </footer>
  )
}

export default Footer
